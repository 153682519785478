import React, { useEffect, useState } from "react"
import { GridLayout } from "../GridLayout"
import { AppSidebar } from "../components/AppSidebar"
import styled, { createGlobalStyle } from "styled-components"
import { AppHeader } from "../components/AppHeader"
import { useLocation, useParams } from "react-router-dom"
import { animated, useTransition } from "react-spring"
import device from "../theme/devices"
import { WithMobileMenuContext } from "../context/MobileMenuContext"
import { WithHeaderContext } from "../context/EventHeaderContext"
import { WithSidebarContext } from "../context/EventSidebarContext"
import { EventBackground } from "../components/Backgrounds/EventBackground"

const WrapperGridLayout = styled(animated(GridLayout))`
  min-height: 100%;
  grid-template-rows: auto 1fr;
`

const Main = styled(animated.main)<{ collapsed?: boolean }>`
  grid-row: 2;
  grid-column: 1 / 8;
  grid-template-rows: auto 1fr auto;
  @media (${device.desktop}) {
    grid-column: ${props => (props.collapsed ? "3 / 15" : "5 / 15")};
    margin-left: -20px;
  }
`

export const EventLayout: React.FC = props => {
  const params = useParams<{ booth: string; product: string }>()

  return (
    <EventBackground>
      <WrapperGridLayout withMarginCols>
        <InnerEventLayout collapsed={!!params.product}>
          {props.children}
        </InnerEventLayout>
      </WrapperGridLayout>
    </EventBackground>
  )
}

export const ToastContainerStyles = createGlobalStyle<{
  sidebarCollapsed: boolean
  dontOveridePosition?: boolean
}>`
  
  .Toastify {
    .Toastify__toast-container {
      @media (${device.desktop}) {
        left: ${props =>
          !props.dontOveridePosition && (props.sidebarCollapsed ? 10 : 26)}%;
        top: 64px;
  
      }  
      color: ${props => props.theme.textColor};
      font-family: Graphik;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
  
    .Toastify__toast {
      background: #FFFFFF;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      border-radius: 0;
      border: none;
      width: 308px;
      height: 47px;
    }
    
    .Toastify__toast-body {
      display: flex;
      align-items: center;
    }  
      
    .Toastify__close-button {
      display: none;
    }
      
    }
    .Toastify__progress-bar {
      background: ${props => props.theme.primaryColor};
      height: 2px;
      border-radius: 0;
    }
  }

`

const InnerEventLayout: React.FC<{ collapsed: boolean }> = props => {
  const params = useParams<{ booth: string; product: string }>()
  const location = useLocation()
  const [mainTransition] = useTransition(
    { children: props.children, cols: params.product ? 11 : 9 },
    {
      from: { opacity: 0, position: "absolute" },
      enter: { opacity: 1, position: "relative" },
      leave: { opacity: 0 },
      expires: -2,
      unique: false,
      reset: true,
    },
    [params.booth, params.product]
  )

  const [canRenderBooth, set] = useState<boolean>(false)
  useEffect(() => {
    if (params.booth) {
      setTimeout(() => set(true), 3000)
    } else if (!location.pathname.includes("checkout")) {
      set(false)
    }
  }, [params.booth])

  return (
    <WithHeaderContext>
      <WithSidebarContext>
        <WithMobileMenuContext>
          <AppHeader collapsed={props.collapsed} />
          <ToastContainerStyles sidebarCollapsed={props.collapsed} />
          <AppSidebar />
        </WithMobileMenuContext>
        {mainTransition((style, { children, cols }) => (
          <GridLayout
            as={Main}
            withMarginCols
            cols={cols}
            style={style}
            collapsed={props.collapsed}
          >
            {params.booth ? canRenderBooth && children : children}
          </GridLayout>
        ))}
      </WithSidebarContext>
    </WithHeaderContext>
  )
}
